//TODO: need to move all tokens to a provider, so that we can inject our token functions here
//http://stackoverflow.com/questions/15666048/service-vs-provider-vs-factory
angular.module('sq.user.auth.interceptor',
  ['sq.common.httpInterceptorBuffer', 'sq.user.auth.tokens'])
  .constant('AUTH_INTERCEPTOR', { eventPrefix: 'sq.user.auth.interceptor:' })

  .factory('authHttpService', function ($rootScope, $timeout, httpBuffer, $log, userTokens, AUTH_INTERCEPTOR) {
    //var thisBuffer = httpBuffer.getInstance(401);
    let timeoutPromise;
    return {
      /**
       * Call this function to indicate that authentication was successfull and trigger a
       * retry of all deferred requests.
       * @param data an optional argument to pass on to $broadcast which may be useful for
       * example if you need to pass through details of the user that was logged in
       * @param configUpdater an optional transformation function that can modify the
       * requests that are retried after having logged in.  This can be used for example
       * to add an authentication token.  It must return the request.
       */
      loginConfirmed: function (data, configUpdater) {
        var updater = configUpdater || function (config) {
          config.headers.Authorization = 'Bearer ' + userTokens.accessToken;
          return config;
        };

        if (timeoutPromise) {
          $timeout.cancel(timeoutPromise);
        }

        timeoutPromise = $timeout(() => {
          $rootScope.$broadcast(AUTH_INTERCEPTOR.eventPrefix + 'loginConfirmed');
        }, 100);

        httpBuffer.retryAll(updater);
      },

      /**
       * Call this function to indicate that authentication should not proceed.
       * All deferred requests will be abandoned or rejected (if reason is provided).
       * @param data an optional argument to pass on to $broadcast.
       * @param reason if provided, the requests are rejected; abandoned otherwise.
       */
      loginCancelled: () => {
        httpBuffer.rejectAll(new Error('login cancelled'));

        if (timeoutPromise) {
          $timeout.cancel(timeoutPromise);
        }

        timeoutPromise = $timeout(() => {
          $rootScope.$broadcast(AUTH_INTERCEPTOR.eventPrefix + 'loginCancelled');
        }, 100);
      }
    };
  })

  /**
   * $http interceptor.
   * On 401 response (without 'ignoreAuthModule' option) stores the request
   * and broadcasts 'event:auth-loginRequired'.
   * On 403 response (without 'ignoreAuthModule' option) discards the request
   * and broadcasts 'event:auth-forbidden'.
   */
  .config(function ($httpProvider) {
    $httpProvider.interceptors.push(($rootScope, $location, $q, httpBuffer, AUTH_INTERCEPTOR) => {
      //var thisBuffer = httpBuffer.getInstance(401);
      return {
        responseError: function (rejection) {
          var config = rejection.config || {};
          // if (config.redirectOn401) {
          let deferred;

          // Send events to indicate that server rejected our request
          // This is PURELY a lifestyle choice; security is unaffected
          // since validation must be server-side
          // could pass config arguments (like config.ignoreAuthModule) to control
          // deprecated loginRequired and forbidden events (401 and 403 resp)
          if (config.ignoreAuthModule) {
            return $q.reject(rejection);
          }

          switch (rejection.status) {
            case 401:
              deferred = httpBuffer.defer(config);
              $rootScope.$broadcast(AUTH_INTERCEPTOR.eventPrefix + `:redirect:login`, rejection, $location.url());
              return $q.reject(rejection);
            case 403:
              $rootScope.$broadcast(AUTH_INTERCEPTOR.eventPrefix + `:redirect:login`, rejection, $location.url());
              return $q.reject(rejection);
            default:
              return $q.reject(rejection);
          }
        }
      };
    });
  });
